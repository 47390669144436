import router from '@/router'
import axios from 'axios'
import store from '@/store'
import webServices from '../../../webServices/api.service'

export default {
  namespaced: true,
  state: {
    backURL: '',
    toURL: '',
    authUser: '',
    authPassword: '',
    user: {},
    accessToken: {},
    userMenu: [],
    gettingTokenRequest: false,
  },
  getters: {
    getAccessToken: state => state.accessToken,
    getUserLoggedIn: state => state.user,
    getUserLogged: state => {
     return  JSON.parse(localStorage.getItem('user'));
    },
  },
  mutations: {
    SET_BACK_URL(state, url) {
      state.backURL = url
    },
    SET_TO_URL(state, url) {
      state.toURL = url
    },
    SET_AUTH_USER(state, value) {
      state.authUser = value
    },
    SET_AUTH_PASSWORD(state, value) {
      state.authPassword = value
    },
    SET_GETTING_TOKEN_REQUEST(state, value) {
      state.gettingTokenRequest = value
    },
    SET_AUTH(state, value) {
      state.user = value.user
      localStorage.setItem('user', JSON.stringify(state.user))
      state.accessToken = value.access_token
      localStorage.setItem('accessToken', state.accessToken)
    },
    SET_USER_FRESH(state, value) {
      state.user = value
      localStorage.setItem('user', JSON.stringify(value))
    },
    LOGOUT(state) {
      state.user = {}
      localStorage.removeItem('user')
      state.accessToken = null
      localStorage.removeItem('accessToken')
      localStorage.removeItem('id_token')
      setTimeout(() => router.replace('/login'), 1000)
    },
    /**
     *
     * @param state
     * @param value
     * @constructor
     */
    SET_USER_MENU(state, value) {
      state.userMenu = value
    },
  },
  actions: {
    async login(context, data) {
      return new Promise((resolve, reject) => {
        axios.post(
          `${process.env.VUE_APP_API_BASE_URL}oauth/token`,
          {
            client_id: process.env.VUE_APP_CLIENT_ID,
            client_secret: process.env.VUE_APP_CLIENT_SECRET,
            grant_type: process.env.VUE_APP_GRANT_TYPE,
            username: data.username,
            password: data.password,
          },
        ).then(response => {
          store.commit('auth/SET_GETTING_TOKEN_REQUEST', false)
          if (response.status === 201 || response.status === 200) {
            window.localStorage.removeItem('id_token')
            window.localStorage.setItem('id_token', response.data.access_token)
            axios.defaults.headers.common.Authorization = `Bearer ${response.data.access_token}`
          }
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    async logout(context) {
      return new Promise((resolve, reject) => {
        webServices
          .get('logout', {}, { headers: { Accept: 'application/json', Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
          .then(response => {
            context.commit('LOGOUT')
            resolve(response)
          })
          .catch(error => {
            if (error.response.status && error.response.status === 401) {
              context.commit('LOGOUT')
            }
            reject(error)
          })
      })
    },
    async me(context, data = {}) {
      return new Promise((resolve, reject) => {
        webServices
          .get('auth/me', { params: data, headers: { Accept: 'application/json', Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            if (error.response.status && error.response.status == 401) {
              context.commit('LOGOUT')
            }
            reject(error)
          })
      })
    },
    async changePassword(context, data) {
      return new Promise((resolve, reject) => {
        webServices
          .post('auth/change-password', data, { headers: { Accept: 'application/json', Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            if (error.response.status && error.response.status == 401) {
              context.commit('LOGOUT')
            }
            reject(error)
          })
      })
    },
    async changeProfile(context, data) {
      return new Promise((resolve, reject) => {
        webServices
          .post('auth/change-profile', data, { headers: { Accept: 'application/json', Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            if (error.response.status && error.response.status == 401) {
              context.commit('LOGOUT')
            }
            reject(error)
          })
      })
    },
    async uploadAvatar(context, data) {
      return new Promise((resolve, reject) => {
        webServices
          .post('auth/upload-avatar', data, { headers: { Accept: 'application/json', Authorization: `Bearer ${localStorage.getItem('accessToken')}`, 'Content-Type': `multipart/form-data; charset=utf-8; boundary=${Math.random().toString().substr(2)}` } })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            if (error.response.status && error.response.status == 401) {
              // context.commit('LOGOUT')
            }
            reject(error)
          })
      })
    },
    /**
     * Obtencion del menu configurado en base de datos
     * @param context
     *
     * @returns {Q.Promise<any>}
     */
    async getUserMenu(context) {

      return webServices.post('usuarios/menu-usuario')
        .then(response => {
          context.commit('SET_USER_MENU', response.data)
        })
        .catch(error => {
          console.log(error)
        })

    },
    /**
     * Obtencion del menu configurado en base de datos
     * @param context
     * @param data
     * @returns {Q.Promise<any>}
     */
    async getUserData(context, data) {
      return webServices.get('user')
        .then(response => {
          console.log('getUserData', response.data)
          context.commit('SET_USER_FRESH', response.data)
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
