import ApiService from '@/webServices/api.service'

export default {
  namespaced: true,
  state: {
    codigos: [],
    excell: [],
    rowsTotal: 0,
    from: 0,
    to: 0,
    currentPage: 1,
    loading: false,
  },
  getters: {

  },
  mutations: {
    setCodigoValidez(state, value) {
      state.codigos = value
    },
    setDescargarExcell(state, value) {
      state.excell = value
    },
    setTotal(state, value) {
      state.rowsTotal = value
    },
    setTo(state, value) {
      state.to = value
    },
    setForm(state, value) {
      state.from = value
    },
    setCurrentPage(state, value) {
      state.currentPage = value
    },
    setLoading(state, value) {
      state.loading = value
    },

  },
  actions: {
    async getCodigoValidez(context, data) {
      context.commit('setLoading', true)
      return ApiService
        .post('alumnos/codigo-validez', data)
        .then(response => {
          context.commit('setCodigoValidez', response.data.data)
          context.commit('setTotal', response.data.data.total)
          context.commit('setForm', response.data.data.from)
          context.commit('setTo', response.data.data.to)
          context.commit('setCurrentPage', response.data.data.current_page)
          context.commit('setLoading', false)
        })
        .catch(error => {
          console.log(error)
          context.commit('setLoading', false)
        })
    },
    async descargarExcell(context, data) {
      context.commit('setLoading', true)
      return ApiService
        .postGetFile('alumnos/exportar-informacion-codigo-validez', data ,'CodigoVaLidez.xlsx')
        .then(response => {
          context.commit('setDescargarExcell', response)
          context.commit('setLoading', false)
        })
        .catch(error => {
          console.log(error)
          context.commit('setLoading', false)
        })
    },

  },
}
