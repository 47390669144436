const routes = [
  {
    path: '/',
    redirect: { name: 'comisiones' },
  },
  {
    path: '/comisiones',
    name: 'comisiones',
    requiresAuth: false,
    component: () => import('@/views/comisiones/Comisiones'),
    meta: {
      pageTitle: 'Comisiones',
      requiresAuth: true,
      breadcrumb: [
        {
          text: 'Lista Comisiones',
          active: true,
        },
      ],
    },
  },
  {
    path: '/comisiones/detalle/:id',
    name: 'detalle_comision',
    requiresAuth: false,
    component: () => import('@/views/comisiones/DetalleComision'),
    meta: {
      pageTitle: 'Comisiones',
      requiresAuth: true,
      breadcrumb: [
        {
          text: 'Detalles de Comisión',
          active: true,
        },
      ],
    },
  },
]

export default routes
