// import webServices from '../../../webServices'

import ApiService from '@/webServices/api.service'

export default {
  namespaced: true,
  state: {
    reporteSeguimientosLista: [],
    optionEmpresa: [],
    optionFicha: [],
    optionTeletutor: [],
    optionDias: [],
    loading: false,
    rowsTotal: 0,
    from: 0,
    to: 0,
    currentPage: null,
    notificaciones: [],

  },
  getters: {

  },
  mutations: {
    setReporteSeguimiento(state, value) {
      state.reporteSeguimientosLista = value.data
      state.rowsTotal = value.total
      state.currentPage = value.current_page
      state.to = value.to
      state.from = value.from
    },
    setNotificaciones(state, value) {
      state.notificaciones = value.data
    },
    setLoading(state, value) {
      state.loading = value
    },
    setOptionDias(state, value) {
      state.optionDias = value
    },

  },
  actions: {
    async getOptionDias(context) {
      context.commit('setLoading', true)
      const url = 'contact-center/list-dias'
      return ApiService
        .get(url)
        .then(response => {
          context.commit('setOptionDias', response.data)
          context.commit('setLoading', false)
        })
        .catch(error => {
          context.commit('setLoading', false)
          console.log(error)
        })
    },
    async getListadoReporteSeguimiento(context, data) {
      context.commit('setLoading', true)
      const url = `contact-center/report-seguimientos?page=${data.page}&item_per_page=${data.perPage}&id_empresa=${data.id_empresa}&id_holding=${data.id_holding}&num_ficha=${data.num_ficha}&id_usuario=${data.id_usuario}&id_dias=${data.id_dias}`
      return ApiService
        .get(url)
        .then(response => {
          context.commit('setReporteSeguimiento', response.data)
          context.commit('setLoading', false)
        })
        .catch(error => {
          context.commit('setLoading', false)
          console.log(error)
        })
    },
    async exportarMenor5000(context, data) {
      context.commit('setLoading', true)
      const url = `contact-center/export-report-seguimientos?id_empresa=${data.id_empresa}&id_holding=${data.id_holding}&num_ficha=${data.num_ficha}&id_usuario=${data.id_usuario}&id_dias=${data.id_dias}`
      return ApiService
        .getGetFile(url, data.filter, 'ReporteSeguimientos.xlsx')
        .then(response => {
          console.log(response)
          context.commit('setLoading', false)
        })
        .catch(error => {
          context.commit('setLoading', false)
          console.log(error)
        })
    },
    async exportar(context, data) {
      context.commit('setLoading', true)
      const url = `contact-center/export-report-seguimientos?id_empresa=${data.id_empresa}&id_holding=${data.id_holding}&num_ficha=${data.num_ficha}&id_usuario=${data.id_usuario}&id_dias=${data.id_dias}`
      return ApiService
        .get(url, data.filter)
        .then(response => {
          console.log(response)
          context.commit('setLoading', false)
        })
        .catch(error => {
          context.commit('setLoading', false)
          console.log(error)
        })
    },
  },
}
