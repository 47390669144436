import ApiService from '@/webServices/api.service'

export default {
  namespaced: true,
  state: {
    listadoEmpresa: [],
    empresa: [],
    contactoEmpresa: [],
    detalleEmpresaVenta: [],
    detalleEmpresaContacto: [],
    detalleEmpresaDireccion: [],
    editarEmpresa: [],
    detalleCompletoEmpresa: [],
    mensajeRespuesta:{},
    errorResponse:[],
    rowsTotal: 0,
    from: 0,
    to: 0,
    currentPage: 1,
    loading: false,
    isBusy: false,
    IdDetalle: 0,
  },
  getters: {},
  mutations: {
    setListadoEmpresa(state, value) {
      state.listadoEmpresa = value
    },
    setDetalleCompletoEmpresa(state, value) {
      state.detalleCompletoEmpresa = value
    },
    setIdEmpresa(state, value) {
      state.IdDetalle = value
    },
    setEditarEmpresa(state, value) {
       state.editarEmpresa = value
    },
    setDetalleEmpresa(state, value) {
      state.detalleEmpresaVenta = value
    },
    setDetalleEmpresaContacto(state, value) {
      state.detalleEmpresaContacto = value
    },
    setBuscarContactoEmpresa(state, value) {
      state.contactoEmpresa = value
    },
    setBuscarEmpresa(state, value) {
      state.empresa = value
    },
    setDetalleEmpresaDireccion(state, value) {
      state.detalleEmpresaDireccion = value
    },
    setGoogleDireccion(state, value) {
      state.detalleEmpresaVenta = value
    },
    setTotal(state, value) {
      state.rowsTotal = value
    },
    setTo(state, value) {
      state.to = value
    },
    setForm(state, value) {
      state.from = value
    },
    setCurrentPage(state, value) {
      state.currentPage = value
    },
    setLoading(state, value) {
      state.loading = value
    },
    setBusy(state, value) {
        state.isBusy = value
    },
    setMensajeRespuesta(state, value) {
      state.mensajeRespuesta = value
    },
    setErrorResponse(state, value) {
          state.errorResponse = value
    },

  },
  actions: {
    async getListadoEmpresa(context, data) {
      context.commit('setLoading', true)
      return ApiService
        .post('empresa/listado', data)
        .then(response => {
          context.commit('setListadoEmpresa', response.data.data.data)
          context.commit('setTotal', response.data.data.total)
          context.commit('setForm', response.data.data.from)
          context.commit('setTo', response.data.data.to)
          context.commit('setCurrentPage', response.data.data.current_page)
          context.commit('setLoading', false)
        })
        .catch(error => {
          console.log(error)
          context.commit('setLoading', false)
        })
    },
    async getDetalleCompletoEmpresa(context, data) {
      context.commit('setLoading', true)
      return ApiService
        .post('empresa/detalle-empresa', data)
        .then(response => {
          context.commit('setDetalleCompletoEmpresa', response.data)
          context.commit('setLoading', false)
        })
        .catch(error => {
          console.log(error)
          context.commit('setErrorResponse', error)
          context.commit('setLoading', false)
        })
    },
    async getDetalleEmpresaVentas(context, data) {
      context.commit('setLoading', true)
      context.commit('setBusy', true)
      return ApiService
        .post('empresa/ventas', data)
        .then(response => {
          console.log("DATITA",response.data.data);
          context.commit('setDetalleEmpresa', response.data.data.data)
          context.commit('setTotal', response.data.data.total)
          context.commit('setForm', response.data.data.from)
          context.commit('setTo', response.data.data.to)
          context.commit('setCurrentPage', response.data.data.current_page)
          context.commit('setLoading', false)
          context.commit('setBusy', false)
        })
        .catch(error => {
          console.log(error)
          context.commit('setLoading', false)
        })
    },
    async getDetalleEmpresaContacto(context, data) {
      context.commit('setLoading', true)
      context.commit('setBusy', true)
      return ApiService
        .post('empresa/contacto', data)
        .then(response => {
          console.log('getDetalleEmpresaContacto', response.data.data.data)
          context.commit('setDetalleEmpresaContacto', response.data.data.data)
          context.commit('setTotal', response.data.data.total)
          context.commit('setForm', response.data.data.from)
          context.commit('setTo', response.data.data.to)
          context.commit('setCurrentPage', response.data.data.current_page)
          context.commit('setLoading', false)
          context.commit('setBusy', false)
        })
        .catch(error => {
          console.log(error)
          context.commit('setLoading', false)
        })
    },
    async getDetalleEmpresaDireccion(context, data) {
      context.commit('setLoading', true)
        context.commit('setBusy', true)
      return ApiService
        .post('empresa/direccion', data)
        .then(response => {
          context.commit('setDetalleEmpresaDireccion', response.data.data.data)
          context.commit('setTotal', response.data.data.total)
          context.commit('setForm', response.data.data.from)
          context.commit('setTo', response.data.data.to)
          context.commit('setCurrentPage', response.data.data.current_page)
          context.commit('setLoading', false)
          context.commit('setBusy', false)
        })
        .catch(error => {
          console.log(error)
          context.commit('setLoading', false)
        })
    }, /*
    async getDireccionGoogleEmpresa(context, data) {
      console.log("KEYYYY",data.key);
      console.log("DATAA LUGAR",data.addres);
      context.commit('setLoading', true)
      return ApiService
        .getGoogleMaps('https://maps.googleapis.com/maps/api/geocode/json',{
          params:{
            'key':data.key,
            'adrres':data.addres,
          },
        })
        .then(response => {
          console.log(response.data)
          context.commit('setGoogleDireccionDireccion', response.data)
        })
        .catch(error => {
          console.log(error)
          context.commit('setLoading', false)
        })
    }, */

    async getDireccionGoogleEmpresa(context, data) {
      context.commit('setLoading', true)
      return ApiService
        .getGoogleMaps(`https://maps.googleapis.com/maps/api/geocode/json?key=${data.key}&address=${data.addres}`)
        .then(response => {
          console.log(response.data)
          context.commit('setGoogleDireccionDireccion', response.data)
          context.commit('setLoading', false)
        })
        .catch(error => {
          console.log(error)
          context.commit('setLoading', false)
        })
    },
    async getEliminarDireccionEmpresa(context, data) {
      context.commit('setLoading', true)
      return ApiService
        .delete(`empresa/eliminar-direccion-empresa?id_direccion_empresa=${data}`)
        .then(response => {
          console.log("DATA ELIMINACION EMPREASA",response.data);
          context.commit('setMensajeRespuesta', response.data)
          context.commit('setLoading', false)
        })
        .catch(error => {
          console.log(error)
          context.commit('setLoading', false)
        })
    },
    async getEliminarContactoEmpresa(context, data) {
      context.commit('setLoading', true)
      return ApiService
        .delete(`empresa/eliminar-contacto-empresa?id_contacto_empresa=${data}`)
        .then(response => {
            console.log("DATA Contacto EMPREASA",response.data);
          context.commit('setMensajeRespuesta', response.data)
          context.commit('setLoading', false)
        })
        .catch(error => {
          console.log(error)
          context.commit('setLoading', false)
        })
    },
    async agregarEditarContactoEmpresa(context, data) {
      context.commit('setLoading', true)
      console.log('DATA ENVIADA ', data)
      return ApiService
        .post('empresa/agregar-editar-contacto-empresa', data)
        .then(response => {
          context.commit('setMensajeRespuesta', response.data)
          context.commit('setLoading', false)
        })
        .catch(error => {
          console.log(error)
          context.commit('setLoading', false)
        })
    },
    async agregarEditarEmpresa(context, data) {
      context.commit('setLoading', true)
      console.log('DATA ENVIADA ', data)
      return ApiService
        .post('empresa/crear-editar-empresa', data)
        .then(response => {
          context.commit('setMensajeRespuesta', response.data)
          context.commit('setLoading', false)
        })
        .catch(error => {
          console.log(error)
          context.commit('setLoading', false)
        })
    },
    async agregarDireccionEmpresa(context, data) {
      context.commit('setLoading', true)
      context.commit('setBusy', true)
      console.log('DATA ENVIADA ', data)
      return ApiService
        .post('empresa/agregar-direccion-empresa', data)
        .then(response => {
          context.commit('setMensajeRespuesta', response.data)
          context.commit('setLoading', false)
          context.commit('setBusy', false)
        })
        .catch(error => {
          console.log(error)
          context.commit('setLoading', false)
        })
    },
    async buscarContactoEmpresa(context, data) {
      context.commit('setLoading', true)
      console.log('DATA ENVIADA Contacto', data)
      return ApiService
        .post('empresa/buscar-contacto-empresa', data)
        .then(response => {
          context.commit('setBuscarContactoEmpresa', response.data)
          context.commit('setLoading', false)
          console.log('REspuestaaaa', response.data)
        })
        .catch(error => {
          console.log(error)
          context.commit('setLoading', false)
        })
    },

  },
}
