
import ApiService from "@/webServices/api.service";

export default {
    namespaced: true,
    state: {
        list: [],
        alumno: [],
        rowsTotal: 1,
        from: 0,
        to: 0,
        listAlumno: [],
        rowsTotalAlumno: 1,
        fromAlumno: 0,
        toAlumno: 0,
        rowsTotalSelect: 0,
        loading:false,
        activo:false,
        loadingAlumnos:false,
        loadingAlumno:false,
        mensaje:'',
    },
    getters: {},
    mutations: {
        setAlumno(state, value) {
            state.alumno = value
        },
        setList(state, value) {
            state.list = value
        },
        setTotal(state, value) {
            state.rowsTotal = value
        },
        setTo(state, value) {
            state.to = value
        },
        setForm(state, value) {
            state.from = value
        },
        setListAlumno(state, value) {
            state.listAlumno = value
        },
        setTotalAlumno(state, value) {
            state.rowsTotalAlumno = value
        },
        setToAlumno(state, value) {
            state.toAlumno = value
        },
        setFormAlumno(state, value) {
            state.fromAlumno = value
        },
        setCurrentPage(state, value) {
            state.currentPage = value
        },
        setLoading(state, value) {
            state.loading = value
        },
        setLoadingAlumnos(state, value) {
            state.loadingAlumnos = value
        },
        setLoadingAlumno(state, value) {
            state.loadingAlumno = value
        },
        setMensaje(state, value) {
            state.mensaje = value
        },
        setEstado(state, value) {
            state.activo = value
        },
    },
    actions: {
        async getListFicha(context, data) {
            context.commit('setLoading', true)
            const url = "reporte-cursos-elearning/data";
            return ApiService
                .post(url, data)
                .then(response => {
                    context.commit('setList', response.data.data)
                    context.commit('setTotal', response.data.total)
                    context.commit('setForm', response.data.from)
                    context.commit('setTo', response.data.to)
                    context.commit('setCurrentPage', response.data.current_page)
                    context.commit('setLoading', false)
                })
                .catch(error => {
                    console.log(error)
                    context.commit('setList', [])
                    context.commit('setTotal', 0)
                    context.commit('setForm', 0)
                    context.commit('setTo', 0)
                    context.commit('setCurrentPage', 0)
                    context.commit('setLoading', false)
                })
        },
        async getListAlumno(context, data) {
            context.commit('setLoadingAlumnos', true)
            const url = "reporte-cursos-elearning/detalle-ficha";
            return ApiService
                .post(url, data)
                .then(response => {
                    context.commit('setListAlumno', response.data.data)
                    context.commit('setTotalAlumno', response.data.total)
                    context.commit('setFormAlumno', response.data.from)
                    context.commit('setToAlumno', response.data.to)
                    context.commit('setLoadingAlumnos', false)
                })
                .catch(error => {
                    console.log(error)
                    context.commit('setList', [])
                    context.commit('setTotal', 0)
                    context.commit('setForm', 0)
                    context.commit('setTo', 0)
                    context.commit('setCurrentPage', 0)
                    context.commit('setLoadingAlumnos', false)
                })
        },
        async getDetalleAlumno(context, data) {
            context.commit('setLoadingAlumno', true)
            const url = "reporte-cursos-elearning/detalle-alumno";
            return ApiService
                .post(url, data)
                .then(response => {
                    context.commit('setAlumno', response.data)
                    context.commit('setLoadingAlumno', false)
                })
                .catch(error => {
                    console.log(error)
                    context.commit('setAlumno', [])
                    context.commit('setLoadingAlumno', false)
                })
        },
        async updateDataAlumno(context, data) {
            context.commit('setLoadingAlumno', true)
            const url = "actualizar-avances/info";
            return ApiService
                .post(url, data)
                .then(response => {
                    context.commit('setMensaje', response.data.data)
                    context.commit('setLoadingAlumno', false)
                })
                .catch(error => {
                    console.log(error)
                    context.commit('setLoadingAlumno', false)
                })
        },
    },
}
