// import webServices from '../../../webServices'

import ApiService from '@/webServices/api.service'

export default {
  namespaced: true,
  state: {
    teletutorias: [],
    optionEmpresa: [],
    optionFicha: [],
    optionTeletutor: [],
    loading: false,
    rowsTotal: 0,
    from: 0,
    to: 0,
    currentPage: null,
    notificaciones: [],

  },
  getters: {

  },
  mutations: {
    setTeletutorias(state, value) {
      state.teletutorias = value.data
      state.rowsTotal = value.total
      state.currentPage = value.current_page
      state.to = value.to
      state.from = value.from
    },
    setNotificaciones(state, value) {
      state.notificaciones = value.data
    },
    setLoading(state, value) {
      state.loading = value
    },
    setOptionFicha(state, value) {
      state.optionFicha = value
    },
    setOptionTeletutor(state, value) {
      state.optionTeletutor = value
    },

  },
  actions: {
    async listadoTeletutoria(context, data) {
      var params = new URLSearchParams(data);
      context.commit('setLoading', true)
      const url = 'contact-center/listado-asignar?'+params.toString();
      return ApiService
        .get(url)
        .then(response => {
          context.commit('setTeletutorias', response.data)
          context.commit('setLoading', false)
        })
        .catch(error => {
          context.commit('setLoading', false)
          console.log(error)
        })
    },
    async optionTeletutor(context) {
      context.commit('setLoading', true)
      const url = 'contact-center/usuario-contact'
      return ApiService
        .get(url)
        .then(response => {
          context.commit('setOptionTeletutor', response.data)
        })
        .catch(error => {
          console.log(error)
        })
    },
    async getOptionFicha(context, data) {
      context.commit('setLoading', true)
      const url = `contact-center/ficha/${data}`
      return ApiService
        .get(url)
        .then(response => {
          context.commit('setOptionFicha', response.data)
          context.commit('setLoading', false)
        })
        .catch(error => {
          context.commit('setLoading', false)
          console.log(error)
        })
    },
    async AsingTeletutorFicha(context, asignarData) {
      var params = new URLSearchParams(asignarData);
      context.commit('setLoading', true)
      const url = 'contact-center/asignar-contact?'+params.toString();
      return ApiService
        .post(url)
        .then(response => {
          context.commit('setLoading', false)
          context.commit('setNotificaciones', response)
        })
        .catch(error => {
          context.commit('setLoading', false)
          console.log(error)
        })
    },
    async exportarMenor5000(context, data) {
      var params = new URLSearchParams(data);
      context.commit('setLoading', true)
      const url = 'contact-center/report-asignar-contact?'+params.toString()
      return ApiService
        .getGetFile(url, data.filter, "TeletutoriaAsignar.xlsx")
        .then(response => {
          console.log(response)
          context.commit('setLoading', false)
        })
        .catch(error => {
          context.commit('setLoading', false)
          console.log(error)
        })
    },
    async exportar(context, data) {
      var params = new URLSearchParams(data);
      context.commit('setLoading', true)
      const url = 'contact-center/report-asignar-contact?'+params.toString()
      return ApiService
        .get(url)
        .then(response => {
          console.log(response)
          context.commit('setLoading', false)
        })
        .catch(error => {
          context.commit('setLoading', false)
          console.log(error)
        })
    },
  },
}
