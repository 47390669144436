const routes = [
  {
    path: '/',
    redirect: { name: 'seguimientos' },
  },
  {
    path: '/seguimientos',
    name: 'seguimientos',
    requiresAuth: false,
    component: () => import('@/views/teletutoria/Seguimientos'),
    meta: {
      pageTitle: 'Seguimientos',
      requiresAuth: true,
      breadcrumb: [
        {
          text: 'Seguimientos',
          active: true,
        },
      ],
    },
  },
]

export default routes
