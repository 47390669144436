import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BFormInvalidFeedback, BTab, BTabs, BCard,
  BFormRadioGroup, BFormCheckboxGroup, BOverlay, BTable, BAvatar, BBadge, BFormSelect, BPagination,
  BInputGroup, BInputGroupAppend, BCardBody, BFormCheckbox, BFormText, BDropdown, BDropdownItem,BButtonToolbar,
  BModal,
  BLink, BImg, BIcon,
} from 'bootstrap-vue'
import AutocompleteInfinity from '@/components/AutocompleteInfinity.vue'

Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component('flat-pickr', flatPickr)
Vue.component('v-select', vSelect)
Vue.component('validation-provider', ValidationProvider)
Vue.component('validation-observer', ValidationObserver)
Vue.component('b-overlay', BOverlay)
Vue.component('b-row', BRow)
Vue.component('b-col', BCol)
Vue.component('b-form', BForm)
Vue.component('b-form-group', BFormGroup)
Vue.component('b-form-input', BFormInput)
Vue.component('b-button', BButton)
Vue.component('b-form-invalid-feedback', BFormInvalidFeedback)
Vue.component('b-tab', BTab)
Vue.component('b-tabs', BTabs)
Vue.component('b-card', BCard)
Vue.component('b-form-radio-group', BFormRadioGroup)
Vue.component('b-form-checkbox-group', BFormCheckboxGroup)
Vue.component('b-link', BLink)
Vue.component('b-img', BImg)
Vue.component('autocomplete-infinity', AutocompleteInfinity)
Vue.component('b-table', BTable)
Vue.component('b-avatar', BAvatar)
Vue.component('b-badge', BBadge)
Vue.component('b-form-select', BFormSelect)
Vue.component('b-pagination', BPagination)
Vue.component('b-input-group', BInputGroup)
Vue.component('b-input-group-append', BInputGroupAppend)
Vue.component('b-card-body', BCardBody)
Vue.component('b-form-checkbox', BFormCheckbox)
Vue.component('b-form-text', BFormText)
Vue.component('b-dropdown', BDropdown)
Vue.component('b-dropdown-item', BDropdownItem)
Vue.component('b-button-toolbar', BButtonToolbar)
Vue.component('b-modal', BModal)
Vue.component('b-icon', BIcon)
