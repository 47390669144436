import ApiService from '@/webServices/api.service'

export default {
  namespaced: true,
  state: {
    listado: [],
    IdDetalle: 0,
    listadoDetalle: [],
    listadoDetalleAlumnos: [],
    docRectificacion: [],
    rowsTotal: 0,
    from: 0,
    to: 0,
    currentPage: 1,
    loading: false,
    isBusy: false,
    doc: 0,
    estado: [],
    upLoadData: [],
    mensaje: [],
  },
  getters: {

  },
  mutations: {
    setListadoRectificaciones(state, value) {
      state.listado = value
    },
    setDetalleRectificacion(state, value) {
      state.IdDetalle = value
    },
    setListadoDetalleRectificacion(state, value) {
      state.listadoDetalle = value
    },
    setListadoDetalleRectificacionAlumnos(state, value) {
      state.listadoDetalleAlumnos = value
    },
    setdocRectificacion(state, value) {
      state.docRectificacion = value
    },
    setTotal(state, value) {
      state.rowsTotal = value
    },
    setTo(state, value) {
      state.to = value
    },
    setForm(state, value) {
      state.from = value
    },
    setCurrentPage(state, value) {
      state.currentPage = value
    },
    setToDoc(state, value) {
      state.doc = value
    },
    setLoading(state, value) {
      state.loading = value
    },
    setIsBusy(state, value) {
      state.isBusy = value
    },
    setUpload(state, value) {
      state.upLoadData = value
    },
    setMensaje(state, value) {
      state.mensaje = value
    },
    setEstadoRectificacion(state, value) {
      state.estado = value
    },

  },
  actions: {
    async getListadoRectificaciones(context, data) {
      context.commit('setLoading', true)
      return ApiService
        .post('rectificaciones/listado', data)
        .then(response => {
          context.commit('setListadoRectificaciones', response.data.data)
          context.commit('setTotal', response.data.total)
          context.commit('setForm', response.data.from)
          context.commit('setTo', response.data.to)
          context.commit('setToDoc', response.data.data.doc)
          context.commit('setCurrentPage', response.data.current_page)
          context.commit('setLoading', false)
        })
        .catch(error => {
          console.log(error)
          context.commit('setLoading', false)
        })
    },
    async getListadoDocumentos(context, data) {
      context.commit('setLoading', true)
      return ApiService
        .post(`rectificaciones/listado-doc/${data}`)
        .then(response => {
          context.commit('setListadoRectificaciones', response.data.data)
          context.commit('setTotal', response.data.total)
          context.commit('setForm', response.data.from)
          context.commit('setTo', response.data.to)
          context.commit('setToDoc', response.data.data.doc)
          context.commit('setCurrentPage', response.data.current_page)
          context.commit('setLoading', false)
        })
        .catch(error => {
          console.log(error)
          context.commit('setLoading', false)
        })
    },
    async getDetalleRectificacion(context, data) {
      context.commit('setLoading', true)
      return ApiService
        .get(`rectificaciones/detalle/${data}`)
        .then(response => {
          context.commit('setListadoDetalleRectificacion', response.data.data)
          context.commit('setEstadoRectificacion', response.data.data.rectificacion.id_estado_edutecno)
          context.commit('setListadoDetalleRectificacionAlumnos', response.data.data.alumnos)
          context.commit('setdocRectificacion', response.data.data.docRectificacion)
          context.commit('setLoading', false)
        })
        .catch(error => {
          console.log(error)
          context.commit('setLoading', false)
        })
    },
    async setUpload(context, data) {
      context.commit('setLoading', true)
      return ApiService
        .post('file/upload/file-get-url', data)
        .then(response => {
          context.commit('setUpload', response.data)
          context.commit('setLoading', false)
          if (response.data == 1) {
            this.getListadoDocumentos()
          }
        })
        .catch(error => {
          console.log(error)
          context.commit('setLoading', false)
        })
    },
    async setUploadSave(context, data) {
      context.commit('setIsBusy', true)
      const formData = new FormData()
      formData.append('file', data.file)
      formData.append('id_rectificacion', data.id_rectificacion)
      formData.append('nombre', data.nombre)
      return ApiService
        .postFormdataFile('rectificaciones/add-doc', formData)
        .then(response => {
          context.commit('setdocRectificacion', response.data)
          context.commit('setIsBusy', false)
        })
        .catch(error => {
          console.log(error)
          context.commit('setIsBusy', false)
        })
    },
    async getListadoDoc(context, data) {
      context.commit('setdocRectificacion', data)
    },
    async updateEstadoRectificacion(context, data) {
      console.log("data",data);
      context.commit('setLoading', true)
      return ApiService
        .post(`rectificaciones/update-estado-rectificacion`, data)
        .then(response => {
          context.commit('setMensaje', response.data.data)
          context.commit('setLoading', false)
        })
        .catch(error => {
          console.log(error)
          context.commit('setLoading', false)
        })
    },

  },
}
