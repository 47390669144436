const routes = [
    {
        path: '/gestionar-certificados',
        name: 'gestionar_certificados',
        requiresAuth: false,
        component: () => import('@/views/gestionCertificados/Listar'),
        meta: {
            pageTitle: 'Gestión Certificado',
            requiresAuth: true,
            breadcrumb: [
                {
                    text: 'Gestión Certificado',
                    active: true,
                },
            ],
        },
    },
   {
        path: '/gestionar-certificados/nuevo',
        name: 'nuevo_certificado',
        requiresAuth: false,
        component: () => import('@/views/gestionCertificados/Agregar'),
        meta: {
            pageTitle: 'Gestión Certificado',
            requiresAuth: true,
            breadcrumb: [
                {
                    text: 'Nuevo',
                    active: true,
                },
            ],
        },
    },
   {
        path: '/gestionar-certificados/editar/:id',
        name: 'editar_certificado',
        requiresAuth: false,
        component: () => import('@/views/gestionCertificados/Editar'),
        meta: {
            pageTitle: 'Gestión Certificado',
            requiresAuth: true,
            breadcrumb: [
                {
                    text: 'Editar',
                    active: true,
                },
            ],
        },
    },
   {
        path: '/gestionar-certificados/copiar/:id',
        name: 'copiar_certificado',
        requiresAuth: false,
        component: () => import('@/views/gestionCertificados/Editar'),
        meta: {
            pageTitle: 'Gestión Certificado',
            requiresAuth: true,
            breadcrumb: [
                {
                    text: 'Nueva Copia',
                    active: true,
                },
            ],
        },
    },
]

export default routes
