export default {
  en: {
    // 1×× Informational
    100: 'Continue',
    101: 'Switching Protocols',
    102: 'Processing',

    // 2×× Success
    200: 'OK',
    201: 'Created',
    202: 'Accepted',
    203: 'Non-authoritative Information',
    204: 'No Content',
    205: 'Reset Content',
    206: 'Partial Content',
    207: 'Multi-Status',
    208: 'Already Reported',
    226: 'IM Used',

    // 3×× Redirection
    300: 'Multiple Choices',
    301: 'Moved Permanently',
    302: 'Found',
    303: 'See Other',
    304: 'Not Modified',
    305: 'Use Proxy',
    307: 'Temporary Redirect',
    308: 'Permanent Redirect',

    // 4×× Client Error
    400: 'Bad Request',
    401: 'Unauthorized',
    402: 'Payment Required',
    403: 'Forbidden',
    404: 'Not Found',
    405: 'Method Not Allowed',
    406: 'Not Acceptable',
    407: 'Proxy Authentication Required',
    408: 'Request Timeout',
    409: 'Conflict',
    410: 'Gone',
    411: 'Length Required',
    412: 'Precondition Failed',
    413: 'Payload Too Large',
    414: 'Request-URI Too Long',
    415: 'Unsupported Media Type',
    416: 'Requested Range Not Satisfiable',
    417: 'Expectation Failed',
    418: 'I’m a teapot',
    421: 'Misdirected Request',
    422: 'Unprocessable Entity',
    423: 'Locked',
    424: 'Failed Dependency',
    426: 'Upgrade Required',
    428: 'Precondition Required',
    429: 'Too Many Requests',
    431: 'Request Header Fields Too Large',
    444: 'Connection Closed Without Response',
    451: 'Unavailable For Legal Reasons',
    499: 'Client Closed Request',

    // 5×× Server Error
    500: 'Internal Server Error',
    501: 'Not Implemented',
    502: 'Bad Gateway',
    503: 'Service Unavailable',
    504: 'Gateway Timeout',
    505: 'HTTP Version Not Supported',
    506: 'Variant Also Negotiates',
    507: 'Insufficient Storage',
    508: 'Loop Detected',
    510: 'Not Extended',
    511: 'Network Authentication Required',
    599: 'Network Connect Timeout Error',
  },
  es: {
    // 1×× Informativo
    100: 'Continuar',
    101: 'Cambio de protocolos',
    102: 'Procesando',

    // 2×× éxito
    200: 'OK',
    201: 'Creado',
    202: 'Aceptado',
    203: 'Información no autorizada',
    204: 'Sin contenido',
    205: 'Restablecer contenido',
    206: 'Contenido parcial',
    207: 'Varios estados',
    208: 'Ya informado',
    226: 'IM usado',

    // 3×× Redirección
    300: 'Varias opciones',
    301: 'Movido Permanentemente',
    302: 'Encontrado',
    303: 'Ver otros',
    304: 'No modificado',
    305: 'Usar proxy',
    307: 'Redireccionamiento temporal',
    308: 'Redireccionamiento permanente',

    // 4×× Error del cliente
    400: 'Petición Incorrecta',
    401: 'No autorizado',
    402: 'Pago requerido',
    403: 'Prohibido',
    404: 'No encontrado',
    405: 'Método no permitido',
    406: 'No aceptable',
    407: 'Se requiere autenticación de proxy',
    408: 'El cliente falló al continuar la petición',
    409: 'Conflicto',
    410: 'Desaparecido',
    411: 'Longitud requerida',
    412: 'Precondición fallida',
    413: 'Carga útil demasiado grande',
    414: 'Request-URI Too Long',
    415: 'Tipo de papel no admitido',
    416: 'Rango solicitado no satisfactorio',
    417: 'Expectativa fallida',
    418: 'Soy una tetera',
    421: 'Solicitud mal dirigida',
    422: 'Error de validación',
    423: 'Bloqueado',
    424: 'Dependencia fallida',
    426: 'Actualización necesaria',
    428: 'Requisito previo',
    429: 'Demasiadas solicitudes',
    431: 'Campos de encabezado de solicitud demasiado grandes',
    444: 'Conexión cerrada sin respuesta',
    451: 'No disponible por motivos legales',
    499: 'Solicitud cerrada del cliente',

    // 5×× Error del servidor
    500: 'Error interno de servidor',
    501: 'No implementado',
    502: 'Puerta de enlace no válida',
    503: 'Servicio no Disponible',
    504: 'Tiempo de espera de la puerta de enlace',
    505: 'Versión HTTP no compatible',
    506: 'La variante también negocia',
    507: 'Almacenamiento insuficiente',
    508: 'Bucle detectado',
    510: 'No extendido',
    511: 'Se requiere autenticación de red',
    599: 'Error de tiempo de espera de conexión a la red',

    // 6xx EnArriendo
    600: 'Error de red. Revise su conexión a Internet',
  },
}
