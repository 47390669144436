// import webServices from '../../../webServices'

import ApiService from '@/webServices/api.service'

export default {
  namespaced: true,
  state: {
    listaSeguimientos: [],
    listaSeguimientosMasivo: [],
    listaHistorialSeguimientos: [],
    listaHistorialAlumnos: [],
    optionsCat1: [],
    optionsCat2: [],
    optionsCat3: [],
    optionsCat4: [],
    infoAlumno: null,
    recargarListHistorialSeguimientos: false,
    recargarListSeguimientosMasivos: false,
    recargarListSeguimientos: false,
    recargarInfoAlumno: false,
    loading: false,
    historialSeguimientosLoading: false,
    historialAlumnoLoading: false,
    infoAlumnoLoading: false,
    readCat2Loading: false,
    readCat3Loading: false,
    readCat4Loading: false,
    rowsTotalHistorialSeguimientos: 0,
    rowsTotalHistorialAlumno: 0,
    rowsTotal: 0,
    rowsTotalMasivo: 0,
    fromHistorialSeguimientos: 0,
    fromHistorialAlumno: 0,
    from: 0,
    fromMasivo: 0,
    toHistorialSeguimientos: 0,
    toHistorialAlumno: 0,
    to: 0,
    toMasivo: 0,
    currentPage: null,
    currentPageMasivo: null,
    currentPageHistorialSeguimientos: null,
    currentPageHistorialAlumno: null,
    notificaciones: [],
    mensaje:'',

  },
  getters: {

  },
  mutations: {
    setSeguimientos(state, value) {
      state.listaSeguimientos = value.data
      state.rowsTotal = value.total
      state.currentPage = value.current_page
      state.to = value.to
      state.from = value.from
    },
    setSeguimientosMasivo(state, value) {
      state.listaSeguimientosMasivo = value.data
      state.rowsTotalMasivo = value.total
      state.currentPageMasivo = value.current_page
      state.toMasivo = value.to
      state.fromMasivo = value.from
    },
    setlistaHistorialSeguimientos(state, value) {
      state.listaHistorialSeguimientos = value.data
      state.rowsTotalHistorialSeguimientos = value.total
      state.currentPageHistorialSeguimientos = value.current_page
      state.toHistorialSeguimientos = value.to
      state.fromHistorialSeguimientos = value.from
    },
    setNotificaciones(state, value) {
      state.notificaciones = value.data
    },
    setLoading(state, value) {
      state.loading = value
    },
    setinfoAlumnoLoading(state, value) {
      state.infoAlumnoLoading = value
    },
    setHistorialAlumnoLoading(state, value) {
      state.historialAlumnoLoading = value
    },
    setHistorialSeguimientosLoading(state, value) {
      state.historialSeguimientosLoading = value
    },
    setOptionCat1(state, value) {
      state.optionsCat1 = value
    },
    setOptionCat2(state, value) {
      state.optionsCat2 = value
    },
    setOptionCat3(state, value) {
      state.optionsCat3 = value
    },
    setOptionCat4(state, value) {
      state.optionsCat4 = value
    },
    setOptionCat2Loading(state, value) {
      state.readCat2Loading = value
    },
    setOptionCat3Loading(state, value) {
      state.readCat3Loading = value
    },
    setOptionCat4Loading(state, value) {
      state.readCat4Loading = value
    },
    setInfoAlumno(state, value) {
      state.infoAlumno = value
    },
    setHistorialAlumno(state, value) {
      state.listaHistorialAlumnos = value
      state.rowsTotalHistorialAlumno = value.total
      state.currentPageHistorialAlumno = value.current_page
      state.toHistorialAlumno = value.to
      state.fromHistorialAlumno = value.from
    },
    setRecargarListHistorialSeguimientos(state, value) {
      state.recargarListHistorialSeguimientos = value
    },
    setRecargarInfoAlumno(state, value) {
      state.recargarInfoAlumno = value
    },
    setRecargarListSeguimientosMasivos(state, value) {
      state.recargarListSeguimientosMasivos = value
    },
    setRecargarListSeguimientos(state, value) {
      state.recargarListSeguimientos = value
    },
    setMensaje(state, value) {
      state.mensaje = value
    },
  },
  actions: {

    async editarInfoAlumno(context, data) {
      context.commit('setinfoAlumnoLoading', true)
      context.commit('setLoading', true)
      return ApiService
        .put2('contact-center/info-alumno', data)
        .then(response => {
          console.log(response)
          context.commit('setinfoAlumnoLoading', false)
          context.commit('setLoading', false)
          context.commit('setNotificaciones', { data: { titulo: `${response.data.title}`, mensaje: `${response.data.message}`, status: `${response.data.status}` } })
          context.commit('setRecargarInfoAlumno', true)
          context.commit('setRecargarListSeguimientosMasivos', true)
          context.commit('setRecargarListSeguimientos', true)
          context.commit('setRecargarListHistorialSeguimientos', true)
          context.commit('setRecargarListSeguimientos', true)
        })
        .catch(error => {
          context.commit('setRecargarInfoAlumno', true)
          context.commit('setLoading', false)
          context.commit('setRecargarListSeguimientosMasivos', true)
          context.commit('setRecargarListSeguimientos', true)
          context.commit('setRecargarListHistorialSeguimientos', true)
          context.commit('setRecargarListSeguimientos', true)
          console.log('error update ', error)
        })
    },
    async editarSeguimiento(context, data) {
      context.commit('setHistorialSeguimientosLoading', true)
      return ApiService
        .put(`contact-center/seguimiento?id_seguimiento=${data.id_seguimiento}&id_categoria=${data.id_categoria}&comentario=${data.comentario}`)
        .then(response => {
          context.commit('setLoading', false)
          context.commit('setNotificaciones', response)
          context.commit('setNotificaciones', { data: { titulo: `${response.data.title}`, mensaje: `${response.data.message}`, status: `${response.data.status}` } })
          context.commit('setRecargarListHistorialSeguimientos', true)
        })
        .catch(error => {
          context.commit('setLoading', false)
          console.log('error update ', error)
        })
    },
    async agregarSeguimiento(context, data) {
      context.commit('setHistorialSeguimientosLoading', true)
      return ApiService
        .post(`contact-center/seguimiento`, data)
        .then(response => {
          console.log('respuesta de agregar ', response)
          context.commit('setLoading', false)
          if (data.data.length > 1) {
            context.commit('setNotificaciones', { data: { titulo: `${response.data.title}`, mensaje: `${response.data.message}`, status: `${response.data.status}` } })
            context.commit('setRecargarListSeguimientosMasivos', true)
          } else {
            context.commit('setNotificaciones', { data: { titulo: `${response.data.title}`, mensaje: `${response.data.message}`, status: `${response.data.status}` } })
            context.commit('setRecargarListHistorialSeguimientos', true)
          }
        })
        .catch(error => {
          context.commit('setLoading', false)
          console.log('error crear ', error)
        })
    },
    async listadoSeguimientos(context, params) {
      context.commit('setLoading', true)
      const url = 'contact-center/asignados?'+params;
      return ApiService
        .get(url)
        .then(response => {
          context.commit('setSeguimientos', response.data)
          context.commit('setLoading', false)
        })
        .catch(error => {
          context.commit('setLoading', false)
          console.log(error)
        })
    },
    async listadoSeguimientosMasivo(context, params) {
      context.commit('setLoading', true)

      const url = 'contact-center/asignados?'+params;
      return ApiService
        .get(url)
        .then(response => {
          context.commit('setSeguimientosMasivo', response.data)
          context.commit('setLoading', false)
        })
        .catch(error => {
          context.commit('setLoading', false)
          console.log(error)
        })
    },
    async listadoHistorialSeguimientos(context, params) {
      context.commit('setHistorialSeguimientosLoading', true)

      const url = 'contact-center/seguimiento?'+params;
      return ApiService
        .get(url)
        .then(response => {
          //console.log(response.data)
          context.commit('setlistaHistorialSeguimientos', response.data)
          context.commit('setHistorialSeguimientosLoading', false)
        })
        .catch(error => {
          context.commit('setHistorialSeguimientosLoading', false)
          console.log(error)
        })
    },
    async getInfoAlumno(context, data) {
      context.commit('setinfoAlumnoLoading', true)
      const url = `contact-center/info-alumno?id_detalle_alumno=${data}`
      return ApiService
        .get(url)
        .then(response => {
          //console.log(response.data)
          context.commit('setInfoAlumno', response.data)
          context.commit('setinfoAlumnoLoading', false)
        })
        .catch(error => {
          context.commit('setinfoAlumnoLoading', false)
          console.log(error)
        })
    },
    async updateDataAlumnoRT(context, data) {
      context.commit('setLoadingAlumno', true)
      const url = "actualizar-avances/info";
      return ApiService
          .post(url, data)
          .then(response => {
            context.commit('setMensaje', response.data.data)
            context.commit('setLoading', false)
          })
          .catch(error => {
            console.log(error)
            context.commit('setLoading', false)
          })
    },
    async getListadoHistorialAlumno(context, data) {
      context.commit('setHistorialAlumnoLoading', true)
      const url = `contact-center/historial-alumno?id_detalle_alumno=${data}`
      return ApiService
        .get(url)
        .then(response => {
          // console.log(response.data)
          context.commit('setHistorialAlumno', response.data)
          context.commit('setHistorialAlumnoLoading', false)
        })
        .catch(error => {
          context.commit('setHistorialAlumnoLoading', false)
          console.log(error)
        })
    },
    async optionCat1(context) {
      // context.commit('setLoading', true)
      const url = 'contact-center/list-categoria1'
      return ApiService
        .get(url)
        .then(response => {
          context.commit('setOptionCat1', response.data)
        })
        .catch(error => {
          console.log(error)
        })
    },
    async optionCat2(context, data) {
      context.commit('setOptionCat2Loading', true)
      const url = `contact-center/list-categoria2?padre_id=${data.value}`
      return ApiService
        .get(url)
        .then(response => {
          // console.log(response.data)
          context.commit('setOptionCat2Loading', false)
          context.commit('setOptionCat2', response.data)
        })
        .catch(error => {
          console.log(error)
        })
    },
    async optionCat3(context, data) {
      context.commit('setOptionCat3Loading', true)
      const url = `contact-center/list-categoria3?padre_id=${data.value}`
      return ApiService
        .get(url)
        .then(response => {
          // console.log(response.data)
          context.commit('setOptionCat3Loading', false)
          context.commit('setOptionCat3', response.data)
        })
        .catch(error => {
          console.log(error)
        })
    },
    async optionCat4(context, data) {
      context.commit('setOptionCat4Loading', true)
      const url = `contact-center/list-categoria4?padre_id=${data.value}`
      return ApiService
        .get(url)
        .then(response => {
          //console.log(response.data)
          context.commit('setOptionCat4Loading', false)
          context.commit('setOptionCat4', response.data)
        })
        .catch(error => {
          console.log(error)
        })
    },
    async getOptionFicha(context, data) {
      context.commit('setLoading', true)
      const url = `contact-center/ficha/${data}`
      return ApiService
        .get(url)
        .then(response => {
          context.commit('setOptionFicha', response.data)
          context.commit('setLoading', false)
        })
        .catch(error => {
          context.commit('setLoading', false)
          console.log(error)
        })
    },
    async exportDataSeguimiento(context, data) {
      context.commit('setLoading', true)
      const url = 'contact-center/report-asignados?'+data;
      return ApiService
          .postGetFile('contact-center/report-asignados',data,"Reporte_seguimiento.xlsx")
          .then(response => {
            context.commit('setLoading', false)
            console.log(response)
          })
          .catch(error => {
            context.commit('setLoading', false)
            console.log(error)
          })
    },
    async exportDataSeguimientoMasivo(context, data) {
      context.commit('setLoading', true)
      const url = 'contact-center/report-asignados?'+data;
      return ApiService
          .postGetFile('contact-center/report-asignados',data,"Reporte_seguimiento_Masivo.xlsx")
          .then(response => {
            context.commit('setLoading', false)
            console.log(response)
          })
          .catch(error => {
            context.commit('setLoading', false)
            console.log(error)
          })
    },
  },
}
