import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import i18n from '@/libs/i18n'
import mixins from '@/mixins'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// Global Functions
import './global-functions'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

// import v-select styles
require('@core/scss/vue/libs/vue-select.scss')

Vue.config.productionTip = false

// Router
// navigation guards before each
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (localStorage.getItem('id_token') === null) {
      Vue.prototype.$notify('An error has occurred', 'Unauthenticated', 'XCircleIcon', 'danger')
      next({
        path: '/login',
        query: { redirect: to.fullPath },
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})

Vue.prototype.$notify = function (title, text, icon, variant) {
  this.$toast({
    component: ToastificationContent,
    props: {
      title: title || 'Alerta',
      text: text || 'An error has occurred',
      icon: icon || 'AlertTriangleIcon',
      variant: variant || 'warning',
    },
  })
}

Vue.prototype.$handleSuccessResponse = function (response, message = null) {
  message = message || 'Action successful.'
  const { result = message } = response.data
  if (response && result) {
    this.$notify('Exitoso!', result, 'CheckCircleIcon', 'success')
  }
}

Vue.prototype.$handleErrorResponse = function (error, retry = true) {
  error = this.parseError(error)
  if (error) {
    if (this.isRetrievable(error) && retry) {
      this.$notify('An error has occurred', error.message, 'XCircleIcon', 'danger')
      throw error
    } else {
      this.$notify('An error has occurred', error.message, 'XCircleIcon', 'danger')
      if (error.errors) {
        const keys = Object.keys(error.errors)
        keys.forEach(k => {
          (error.errors[k] || []).forEach(element => {
            if (element) {
              this.$notify('An error has occurred', element, 'AlertTriangleIco', 'warning')
            }
          })
        })
      }
    }
  }
}

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
