const routes = [
  {
    path: '/',
    redirect: { name: 'reporte-seguimientos' },
  },
  {
    path: '/reporte-seguimientos',
    name: 'reporte_seguimientos',
    requiresAuth: false,
    component: () => import('@/views/teletutoria/ReporteSeguimientos'),
    meta: {
      pageTitle: 'Reporte',
      requiresAuth: true,
      breadcrumb: [
        {
          text: 'Seguimientos',
          active: true,
        },
      ],
    },
  },
]

export default routes
