const routes = [
  {
    path: '/',
    redirect: { name: 'usuarios' },
  },
  {
    path: '/usuarios',
    name: 'usuarios',
    requiresAuth: false,
    component: () => import('@/views/usuarios/Usuarios'),
    meta: {
      pageTitle: 'Usuarios',
      requiresAuth: true,
      breadcrumb: [
        {
          text: 'Usuarios',
          active: true,
        },
      ],
    },
  },
]

export default routes
