const routes = [
    {
        path: '/',
        redirect: { name: 'manage_exec' },
    },
    {
        path: '/manage-exec',
        name: 'manage_exec',
        requiresAuth: false,
        component: () => import('@/views/gestionFiltro/GestionFiltro'),
        meta: {
            pageTitle: 'Gestor de Ejecuciones',
            requiresAuth: true,
            breadcrumb: [
                {
                    text: 'Gestión de Filtros',
                    active: true,
                },
            ],
        },
    },
]

export default routes
