import ApiService from '../../../webServices/api.service'

export default {
  namespaced: true,
  state: {

  },
  getters: {

  },
  mutations: {

  },
  actions: {
    async sendAcl(context, data) {
      const routeData = []
      for (const route of data) {
        if (route.name != null) {
          routeData.push({
            name: route.name,
            url: route.path,
            description: '',
          })
        }
      }

      return ApiService
        .post('acl/send', {acl: routeData})
        .then(response => {
          if(response.data.status != null && !response.data.status){
            console.log(response.data.message);
          }
        })
        .catch(error => {
          console.log(error)
        })

    },
  },
}
