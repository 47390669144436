// import webServices from '../../../webServices'

import ApiService from "@/webServices/api.service";

export default {
    namespaced: true,
    state: {
        list: [],
        holding: [],
        rowsTotal: 0,
        from: 0,
        to: 0,
        currentPage: 1,
        exportData: [],
        certificado: [],
        otecHolding: [],
        loading:false,
        state:0,
        url: null,
        urlImage: null,
        response: null,
        mensaje: null,
    },
    getters: {},
    mutations: {
        setList(state, value) {
            state.list = value
        },
        setTotal(state, value) {
            state.rowsTotal = value
        },
        setTo(state, value) {
            state.to = value
        },
        setForm(state, value) {
            state.from = value
        },
        setCurrentPage(state, value) {
            state.currentPage = value
        },
        setLoading(state, value) {
            state.loading = value
        },
        setCertificado(state, value) {
            state.certificado = value
        },
        setEstado(state, value) {
            state.state = value
        },
        setUrl(state, value) {
            state.url = value
        },
        setUrlImage(state, value) {
            state.urlImage = value
        },
        setResponse(state, value) {
            state.response = value
        },
        setMensaje(state, value) {
            state.mensaje = value
        },
        setOtecHolding(state, value) {
            state.otecHolding = value
        },
    },
    actions: {
        async getList(context, data) {
            context.commit('setLoading', true)
            const url = 'gestion-certificados/listado?page='+ data.page +'&perPage='+ data.perPage +'&holding='+ data.filter.holding +'&otec='+ data.filter.otec +'&curso='+ data.filter.curso +'&empresa='+ data.filter.empresa;
            return ApiService
                .get(url)
                .then(response => {
                    context.commit('setList', response.data.data)
                    context.commit('setTotal', response.data.total)
                    context.commit('setForm', response.data.from)
                    context.commit('setTo', response.data.to)
                    context.commit('setCurrentPage', response.data.current_page)
                    context.commit('setLoading', false)
                })
                .catch(error => {
                    console.log(error)
                    context.commit('setLoading', false)
                })
        },
        async getByID(context, data) {
            context.commit('setLoading', true)
            const url = 'gestion-certificados/detalle/'+data;
            return ApiService
                .get(url, data)
                .then(response => {
                    context.commit('setCertificado', response.data)
                    context.commit('setLoading', false)
                })
                .catch(error => {
                    console.log(error)
                    context.commit('setLoading', false)
                })
        },
        async getOtecHolding(context, data) {
            context.commit('setLoading', true)
            //const url = 'entidades/otec-holding&empresa='+ data.empresa + '&holding=' + data.holding + '&curso=' + data.curso;
            const url = 'entidades/otec-holding';
            return ApiService
                .post(url, data)
                .then(response => {
                    context.commit('setOtecHolding', response.data)
                    context.commit('setLoading', false)
                })
                .catch(error => {
                    console.log(error)
                    context.commit('setLoading', false)
                })
        },
        async actualizarEstado(context, data) {
            context.commit('setLoading', true)
            const url = 'gestion-certificados/actualizar-estado';
            return ApiService
                .post(url, data)
                .then(response => {
                    context.commit('setEstado', response.data)
                    context.commit('setLoading', false)
                })
                .catch(error => {
                    console.log(error)
                    context.commit('setLoading', false)
                })
        },
        async generarCertificadoDemo(context, data) {
            context.commit('setLoading', true)
            const url = 'file/certificado/export-pdf';
            return ApiService
                .post(url, data)
                .then(response => {
                    context.commit('setUrl', response.data.url)
                    context.commit('setLoading', false)
                })
                .catch(error => {
                    console.log(error)
                    context.commit('setLoading', false)
                })
        },
        async generarCertificadoAlumno(context, data) {
            context.commit('setLoading', true)
            const url = 'file/certificado/export-pdf-alumno';
            return ApiService
                .post(url, data)
                .then(response => {
                    context.commit('setUrl', response.data.url)
                    context.commit('setLoading', false)
                })
                .catch(error => {
                    console.log(error)
                    context.commit('setLoading', false)
                })
        },
        async modificarCertificado(context, data) {
            context.commit('setLoading', true)
            const url = 'gestion-certificados/modificar';
            return ApiService
                .post(url, data)
                .then(response => {
                    context.commit('setResponse', response.data.status)
                    context.commit('setMensaje', response.data.message)
                    context.commit('setLoading', false)
                })
                .catch(error => {
                    console.log(error)
                    context.commit('setLoading', false)
                })
        },
        async insertarCertificado(context, data) {
            context.commit('setLoading', true)
            const url = 'gestion-certificados/insertar';
            return ApiService
                .post(url, data)
                .then(response => {
                    context.commit('setResponse', response.data.status)
                    context.commit('setMensaje', response.data.message)
                    context.commit('setLoading', false)
                })
                .catch(error => {
                    console.log(error)
                    context.commit('setLoading', false)
                })
        },
        async uploadFileImage(context, data) {
            context.commit('setLoading', true)
            const url = 'file/upload/file-get-url';
            let formData = new FormData();
            formData.append('file', data.file[0]);
            return ApiService
                .postFormdataFile(url, formData)
                .then(response => {
                    context.commit('setUrlImage', response.data.url)
                    context.commit('setLoading', false)
                })
                .catch(error => {
                    console.log(error)
                    context.commit('setLoading', false)
                })
        },
    },
}
