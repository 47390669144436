const routes = [
  {
    path: '/',
    redirect: { name: 'codigo_validez' },
  },
  {
    path: '/codigo-validez',
    name: 'codigo_validez',
    requiresAuth: false,
    component: () => import('@/views/codigoValidez/CodigoValidez'),
    meta: {
      pageTitle: 'Codigos Validez',
      requiresAuth: true,
      breadcrumb: [
        {
          text: 'Codigo Validez',
          active: true,
        },
      ],
    },
  },
]

export default routes
