
import ApiService from "@/webServices/api.service";

export default {
    namespaced: true,
    state: {
        holding: [],
        rowsTotalSelect: 0,
        toSelect: 0,
        lastPage: 0,
        valueFilter: [],
        currentPage: 1,
        noData:true,
        loading:false,

    },
    getters: {},
    mutations: {
        setData(state, value) {
            console.log('valueeee',value)
            state.valueFilter =value
        },
        setTotalSelect(state, value) {
            state.rowsTotalSelect = value
        },
        setToSelect(state, value) {
            console.log(value, 'to')
            state.noData = value == null ? false : true
            state.toSelect = value

        },
        setLastPage(state, value) {
            state.lastPage = value
        },
        setLoading(state, value) {
            state.loading = value
        },
    },
    actions: {
        async getValue(context, data) {
            context.commit('setLoading', true)
            const url = data.url + '?'+ data.query_string ;
            return ApiService
                .get(url, data)
                .then(response => {
                    context.commit('setData', response.data.data)
                    context.commit('setTotalSelect', response.data.total)
                    context.commit('setToSelect', response.data.to)
                    context.commit('setLastPage', response.data.last_page)
                    context.commit('setLoading', false)
                })
                .catch(error => {
                    console.log(error)
                })
        },
    },
}
