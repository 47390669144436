const routes = [
    {
        path: '/',
        redirect: { name: 'imagen' },
    },
    {
        path: '/imagen',
        name: 'imagen',
        requiresAuth: false,
        component: () => import('@/views/imagenes/Listar'),
        meta: {
            pageTitle: 'Imagen',
            requiresAuth: true,
            breadcrumb: [
                {
                    text: 'Listar',
                    active: true,
                },
            ],
        },
    },
    ]

export default routes
