// import webServices from '../../../webServices'

import ApiService from "@/webServices/api.service";

export default {
    namespaced: true,
    state: {
        list: [],
        holding: [],
        rowsTotal: 0,
        from: 0,
        to: 0,
        currentPage: 1,
        exportData: [],
        loading:false,
        mensaje:'',
    },
    getters: {},
    mutations: {
        setList(state, value) {
            state.list = value
        },
        setTotal(state, value) {
            state.rowsTotal = value
        },
        setTo(state, value) {
            state.to = value
        },
        setForm(state, value) {
            state.from = value
        },
        setCurrentPage(state, value) {
            state.currentPage = value
        },
        setLoading(state, value) {
            state.loading = value
        },
        setMensaje(state, value) {
            state.mensaje = value
        },
    },
    actions: {
        async getList(context, data) {
            context.commit('setLoading', true)
            const url = "reporte-interno/data?sort_by=" + data.sort_by + '&page=' + data.page + '&item_per_page=' + data.perPage;
            return ApiService
                .post(url, data)
                .then(response => {
                    context.commit('setList', response.data.data)
                    context.commit('setTotal', response.data.total)
                    context.commit('setForm', response.data.from)
                    context.commit('setTo', response.data.to)
                    context.commit('setCurrentPage', response.data.current_page)
                    context.commit('setLoading', false)
                })
                .catch(error => {
                    console.log(error)
                    context.commit('setLoading', false)
                })
        },
        async exportData(context, data) {
            const url = 'reporte-interno/export-all';
         //const url = 'reporte-interno/data-export?sort_by=' + data.sortField + '&page=' + data.page + '&item_per_page=' + data.perPage;
            context.commit('setLoading', true)
            return ApiService
                .postGetFile(url, data.filter,"Reporte_interno.xlsx")
                .then(response => {
                    context.commit('setLoading', false)
                    console.log(response)
                })
                .catch(error => {
                    context.commit('setLoading', false)
                    console.log(error)
                })
        },
        async exportAllData(context, data) {
            //const url = 'reporte-interno/data-export?sort_by=' + data.sortField + '&page=' + data.page + '&item_per_page=' + data.perPage;
            const url = 'reporte-interno/export-all';
            context.commit('setLoading', true)
            return ApiService
                .post(url, data.filter)
                .then(response => {
                    context.commit('setLoading', false)
                    context.commit('setMensaje', response.data.message)
                  console.log(response)
                })
                .catch(error => {
                    context.commit('setLoading', false)
                    console.log(error)
                })
        },
    },
}
