import Vue from 'vue'
import VueRouter from 'vue-router'

// Modules routes
import usuarios from '@/router/modules/usuarios'
import teletutoria from '@/router/modules/teletutoria'
import descuentoProductos from './modules/descuentoProductos'
import profile from './modules/profile'
import reportesInternos from './modules/reportesInternos'
import reportesClientes from './modules/reportesClientes'
import reportesCursos19 from './modules/reportesCursos19'
import reportesCursosPresenciales from './modules/reportesCursosPresenciales'
import certificadoAlumno from './modules/certificadoAlumno'
import codigoValidez from './modules/codigoValidez'
import listadoRectificaciones from './modules/listadoRectificaciones'
import retencionSii from './modules/retencionSii'
import comisiones from './modules/comisiones'
import reporteSeguimientos from '@/router/modules/reporteSeguimientos'
import seguimientos from '@/router/modules/seguimientos'
import empresa from './modules/empresa'
import reportesMutual from "./modules/reportesMutual";
import codigosTablet from "./modules/codigosTablet";
import gestionCertificados from "./modules/gestionCertificado";
import reporteBonoContact from "./modules/reporteBonoContact";
import imagen from "./modules/imagenes";
import infoFicha from "./modules/infoFicha";
import filtros from "./modules/gestionFiltro";

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home'),
      meta: {
        pageTitle: 'Inicio',
        requiresAuth: true,
        breadcrumb: [
          {
            text: 'Inicio',
            active: true,
          },
        ],
      },
    },
    ...descuentoProductos,
    ...profile,
    ...reportesInternos,
    ...usuarios,
    ...reportesClientes,
    ...reportesMutual,
    ...reportesCursos19,
    ...reportesCursosPresenciales,
    ...certificadoAlumno,
    ...comisiones,
    ...codigoValidez,
    ...listadoRectificaciones,
    ...retencionSii,
    ...teletutoria,
    ...reporteSeguimientos,
    ...seguimientos,
    ...empresa,
    ...codigosTablet,
    ...gestionCertificados,
    ...reporteBonoContact,
    ...imagen,
    ...infoFicha,
    ...filtros,
    {
      path: '/login',
      name: 'login',
      requiresAuth: false,
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/loading',
      name: 'loading',
      requiresAuth: false,
      component: () => import('@/views/Loading.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      requiresAuth: false,
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

export default router
