const routes = [
  {
    path: '/',
    redirect: { name: 'reportes_cursos_presenciales' },
  },
  {
    path: '/reportes-cursos-presenciales',
    name: 'reportes_cursos_presenciales',
    requiresAuth: false,
    component: () => import('@/views/reportesCursosPresenciales/ReportesCursosPresenciales'),
    meta: {
      pageTitle: 'Reportes Cursos Presenciales',
      requiresAuth: true,
      breadcrumb: [
        {
          text: 'Reportes Cursos Presenciales',
          active: true,
        },
      ],
    },
  },
]

export default routes
