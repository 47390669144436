const routes = [
  {
    path: '/',
    redirect: { name: 'descuento_productos' },
  },
  {
    path: '/descuento-productos',
    name: 'descuento_productos',
    requiresAuth: false,
    component: () => import('@/views/descuentoProductos/DescuentoProductos'),
    meta: {
      pageTitle: 'Descuento Productos',
      requiresAuth: true,
      breadcrumb: [
        {
          text: 'Descuento productos',
          active: true,
        },
      ],
    },
  },
]

export default routes
