// import webServices from '../../../webServices'

import ApiService from "@/webServices/api.service";

export default {
    namespaced: true,
    state: {
        list: [],
        rowsTotal: 0,
        from: 0,
        to: 0,
        currentPage: 1,
        loading:false,
        state:0,
        url: null,
        urlImage: null,
        response: null,
        mensaje: null,
    },
    getters: {},
    mutations: {
        setList(state, value) {
            state.list = value
        },
        setTotal(state, value) {
            state.rowsTotal = value
        },
        setTo(state, value) {
            state.to = value
        },
        setForm(state, value) {
            state.from = value
        },
        setCurrentPage(state, value) {
            state.currentPage = value
        },
        setLoading(state, value) {
            state.loading = value
        },
        setUrl(state, value) {
            state.url = value
        },
        setUrlImage(state, value) {
            state.urlImage = value
        },
        setResponse(state, value) {
            state.response = value
        },
        setMensaje(state, value) {
            state.mensaje = value
        },
    },
    actions: {
        async getList(context, data) {
            context.commit('setLoading', true)
            const url = 'gestion-imagenes/listado?page='+ data.page +'&perPage='+ data.perPage +'&filter='+ data.filter;
            return ApiService
                .get(url)
                .then(response => {
                    context.commit('setList', response.data.data)
                    context.commit('setTotal', response.data.total)
                    context.commit('setForm', response.data.from)
                    context.commit('setTo', response.data.to)
                    context.commit('setCurrentPage', response.data.current_page)
                    context.commit('setLoading', false)
                })
                .catch(error => {
                    console.log(error)
                    context.commit('setLoading', false)
                })
        },
        async getListFilter(context, data) {
            context.commit('setLoading', true)
            const url = 'gestion-imagenes/listado-filter';
            return ApiService
                .get(url)
                .then(response => {
                    context.commit('setList', response.data)
                    context.commit('setLoading', false)
                })
                .catch(error => {
                    console.log(error)
                    context.commit('setLoading', false)
                })
        },
        async insertar(context, data) {
            context.commit('setLoading', true)
            const url = 'gestion-imagenes/insertar';
            let formData = new FormData();
            formData.append('file', data.file);
            formData.append('nombre', data.nombre);
            return ApiService
                .post(url, formData)
                .then(response => {
                    context.commit('setList', response.data.data)
                    context.commit('setTotal', response.data.total)
                    context.commit('setForm', response.data.from)
                    context.commit('setTo', response.data.to)
                    context.commit('setCurrentPage', response.data.current_page)
                    context.commit('setLoading', false)
                })
                .catch(error => {
                    console.log(error)
                    context.commit('setLoading', false)
                })
        },
        async uploadFileImage(context, data) {
            context.commit('setLoading', true)
            const url = 'file/upload/file-get-url';
            let formData = new FormData();
            formData.append('file', data.file[0]);
            return ApiService
                .postFormdataFile(url, formData)
                .then(response => {
                    context.commit('setUrlImage', response.data.url)
                    context.commit('setLoading', false)
                })
                .catch(error => {
                    console.log(error)
                    context.commit('setLoading', false)
                })
        },
    },
}
