// import webServices from '../../../webServices'

import ApiService from '@/webServices/api.service'

export default {
  namespaced: true,
  state: {
    descuentos: [],
    selectTipoProducto: [],
    selectModality: [],
    selectTipoDescuento: [],
    selectFormDescuentos: [],
    totalDescuento: 0,
    rowsTotal: 0,
    from: 0,
    to: 0,
    descuentoEditar: [],
    notificaciones: [],
    currentPage: null,
    loading: false,

  },
  getters: {

  },
  mutations: {
    setDescuentos(state, value) {
      state.descuentos = value
      state.rowsTotal = value.total
      state.currentPage = value.current_page
      state.to = value.to
      state.from = value.from
    },
    setSelectTipoProducto(state, value) {
      state.selectTipoProducto = value
    },
    setSelectModality(state, value) {
      state.selectModality = value
    },
    setSelectTipoDescuento(state, value) {
      state.selectTipoDescuento = value
    },
    setDescuentoEditar(state, value) {
      state.descuentoEditar = value
    },
    setNotificaciones(state, value) {
      state.notificaciones = value.data
    },
    setLoading(state, value) {
      state.loading = value
    },
  },
  actions: {
    async getDescuentos(context, data) {
      context.commit('setLoading', true)
      const url = `descuentos/listado?page=${data.page}&item_per_page=${data.perPage}&tipoProducto=${data.tipoProducto}&tipoDescuento=${data.tipoDescuento}&modalidad=${data.modalidad}&activo=${data.descuentoActivo}`
      return ApiService
        .post(url)
        .then(response => {
          context.commit('setDescuentos', response.data.data)
          context.commit('setLoading', false)
        })
        .catch(error => {
          context.commit('setLoading', false)
          console.log(error)
        })
    },
    async getSelectDescuentos(context, data) {
      return ApiService
        .get('descuentos/info-descuento-select', data)
        .then(response => {
          context.commit('setSelectTipoProducto', response.data.tipoProducto),
          context.commit('setSelectModality', response.data.modality),
          context.commit('setSelectTipoDescuento', response.data.tipoDescuento)
        })
        .catch(error => {
          console.log('error ', error)
        })
    },
    async agregarEditarDescuento(context, data) {
      context.commit('setLoading', true)
      return ApiService
        .post(`descuentos/crear-editar-descuento?id=${data.id}&valor_descuento=${data.valorDescuento
        }&tipo_producto=${data.tipoProducto}&modalidad=${data.tipoModalidad}&tipo_descuento=${data.tipoDescuento}`)
        .then(response => {
          context.commit('setLoading', false)
          context.commit('setNotificaciones', response)
          console.log('respuesta ', response)
        })
        .catch(error => {
          context.commit('setLoading', false)
          console.log('error crear ', error)
        })
    },
    async eliminarDescuento(context, data) {
      context.commit('setLoading', true)
      return ApiService
        .delete(`descuentos/eliminar-descuento?id=${data}`)
        .then(response => {
          context.commit('setLoading', false)
          context.commit('setNotificaciones', response)
        })
        .catch(error => {
          context.commit('setLoading', false)
          console.log('error eliminar ', error)
        })
    },
    async activarDesactivarDescuento(context, data) {
      context.commit('setLoading', true)
      return ApiService
        .put(`descuentos/desactivar-activar-descuento?id=${data}`)
        .then(response => {
          context.commit('setLoading', false)
          context.commit('setNotificaciones', response)
        })
        .catch(error => {
          context.commit('setLoading', false)
          console.log('error Activar/Desactivar ', error)
        })
    },

  },
}
