const routes = [
    {
        path: '/',
        redirect: { name: 'reporte_bono_contact' },
    },
    {
        path: '/reporte-bono-contact',
        name: 'reporte_bono_contact',
        requiresAuth: false,
        component: () => import('@/views/reporteBonoContact/ReporteBonoContact'),
        meta: {
            pageTitle: 'Reporte contact',
            requiresAuth: true,
            breadcrumb: [
                {
                    text: 'Reporte contact',
                    active: true,
                },
            ],
        },
    },
]

export default routes