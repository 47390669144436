
export default {
  namespaced: true,
  state: {
  },
  getters: {

  },
  mutations: {

  },
  actions: {
    async getAvatars(context, data) {
      return await new Promise((resolve, reject) => {
        webServices
          .get('users-avatars', { params: data, headers: { Accept: 'application/json', Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            if (error.response.data.message == 'Unauthenticated.') {
              context.commit('appAuth/LOGOUT', null, { root: true })
            } else {
              reject(error)
            }
          })
      })
    },
    async storeAvatar(context, data) {
      return await new Promise((resolve, reject) => {
        webServices
          .post('users-avatars', data, { headers: { Accept: 'application/json', Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            if (error.response.data.message == 'Unauthenticated.') {
              context.commit('appAuth/LOGOUT', null, { root: true })
            } else {
              reject(error)
            }
          })
      })
    },
    async getAvatar(context, data) {
      return await new Promise((resolve, reject) => {
        webServices
          .get(`users-avatars/${data}`, { headers: { Accept: 'application/json', Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            if (error.response.data.message == 'Unauthenticated.') {
              context.commit('appAuth/LOGOUT', null, { root: true })
            } else {
              reject(error)
            }
          })
      })
    },
    async updateAvatar(context, data) {
      data.params.append('_method', 'PUT')

      return await new Promise((resolve, reject) => {
        webServices
          .post(`users-avatars/${data.id}`, data.params, { headers: { Accept: 'application/json', Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            if (error.response.data.message == 'Unauthenticated.') {
              context.commit('appAuth/LOGOUT', null, { root: true })
            } else {
              reject(error)
            }
          })
      })
    },
    async deleteAvatar(context, data) {
      return await new Promise((resolve, reject) => {
        webServices
          .delete(`users-avatars/${data}`, { headers: { Accept: 'application/json', Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            if (error.response.data.message == 'Unauthenticated.') {
              context.commit('appAuth/LOGOUT', null, { root: true })
            } else {
              reject(error)
            }
          })
      })
    },

  },
}
