import axios from 'axios'
import store from '../store'
import router from '../router'

// Global variables
const apiBaseUrl = process.env.VUE_APP_API_BASE_URL
const clientId = process.env.VUE_APP_CLIENT_ID
const clientSecret = process.env.VUE_APP_CLIENT_SECRET
const grantType = process.env.VUE_APP_GRANT_TYPE

// Add authorization token to all request
axios.interceptors.request.use(config => {
  const token = window.localStorage.getItem('id_token')
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  config.headers['Content-Type'] = 'application/json'
  config.headers.Accept = 'application/json'
  config.headers.idperfil = window.localStorage.getItem('id_perfil')
  config.headers.idusuario = window.localStorage.getItem('id_usuario')
  return config
}, error => Promise.reject(error))

// Check all responses in order to handle authorization error
axios.interceptors.response.use(response => response, error => {
  const originalRequest = error.config
  if (error.response.status === 401 && !originalRequest._retry) {
    if (!store.state.auth.gettingTokenRequest) {
      store.commit('auth/SET_GETTING_TOKEN_REQUEST', true)
      originalRequest._retry = true
      let user = window.atob(store.state.auth.authUser)
      const pass = window.atob(store.state.auth.authPassword)
      user=user.split('-');
      return axios.post(`${apiBaseUrl}oauth/token`, {
        client_id: clientId,
        client_secret: clientSecret,
        scope: '*',
        grant_type: grantType,
        username: user[0],
        password: pass,
      }, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      }).then(res => {
        store.commit('auth/SET_GETTING_TOKEN_REQUEST', false)
        if (res.status === 201 || res.status === 200) {
          window.localStorage.removeItem('id_token')
          window.localStorage.setItem('id_token', res.data.access_token)
          axios.defaults.headers.common.Authorization = `Bearer ${res.data.access_token}`
          return axios(originalRequest)
        }
        router.push('login')
      })
    }
  }
  return error
})

const ApiService = {
  /**
     * Send the GET HTTP request
     * @param resource
     * @param slug
     * @returns {*}
     */
  async get(resource, slug = '') {
    return axios.get(`${apiBaseUrl}api/${resource}`).then(response => response).catch(error => {
      console.log(error)
      throw error
    })
  },
  async getGoogleMaps(resource, slug = '') {
    console.log("getGoogleMaps",resource);
    return axios.get(`${resource}`).then(response => response).catch(error => {
      console.log(error)
      throw error
    })
  },

  /**
     * Set the POST HTTP request
     * @param resource
     * @param params
     * @returns {*}
     */
  post(resource, params) {
    return axios.post(`${apiBaseUrl}api/${resource}`, params)
      .then(response => response).catch(error => {
        console.log(error)
        throw error
      })
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  postDirect(resource, params) {
    return axios.post(`${apiBaseUrl}${resource}`, params)
      .then(response => response).catch(error => {
        console.log(error)
        throw error
      })
  },

  /**
     * Set the POST HTTP request with formdata
     * @param resource
     * @param formData
     * @returns {*}
     */
  postFormdata(resource, formData) {
    return axios.post(`${apiBaseUrl}api/${resource}`, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params: formData,
    }).then(response => {
      console.log(response)
      return response
    })
  },
  /**
     * Set the POST HTTP request with formdata
     * @param resource
     * @param formData
     * @returns {*}
     */
  postFormdataFile(resource, formData) {
    const config = {
      headers:{'Content-Type' : 'multipart/form-data'}
    };
    return axios.post(`${apiBaseUrl}api/${resource}`, formData,config).then(response => {
      console.log(response)
      return response
    })
  },

  /**
     * Send the UPDATE HTTP request
     * @param resource
     * @param slug
     * @param params
     * @returns {IDBRequest<IDBValidKey> | Promise<void>}
     */
  update(resource, slug, params) {
    return axios.update(`${apiBaseUrl}api/${resource}/${slug}`, {
      params,
    }).then(response => {
      console.log(response)
      return response
    })
  },

  /**
     * Send the PUT HTTP request
     * @param resource
     * @param params
     * @returns {IDBRequest<IDBValidKey> | Promise<void>}
     */
  put(resource, params) {
    return axios.put(`${apiBaseUrl}api/${resource}`, {
      params,
    }).then(response => {
      console.log(response)
      return response
    })
  },
  /**
     * Send the PUT HTTP request
     * @param resource
     * @param params
     * @returns {IDBRequest<IDBValidKey> | Promise<void>}
     */
  put2(resource, params) {
    return axios.put(`${apiBaseUrl}api/${resource}`, params).then(response => {
      console.log(response)
      return response
    })
  },

  /**
     * Send the DELETE HTTP request
     * @param resource
     * @returns {*}
     */
  delete(resource) {
    return axios.delete(`${apiBaseUrl}api/${resource}`).then(response => {
      console.log(response)
      return response
    })
  },

  exportExcel(resource, params) {
    return axios.post(`${resource}`, params, {
      responseType: 'blob',
    }).then(response => {
      FileSaver.saveAs(response.data, 'fichero.xlsx')
    })
  },

  postGetFile(resource, params, fileName) {
    return axios({
      url: `${apiBaseUrl}api/${resource}`,
      method: 'POST',
      data: params,
      responseType: 'blob',
    }).then(response => {
      const fileURL = window.URL.createObjectURL(new Blob([response.data]))
      const fileLink = document.createElement('a')
      fileLink.href = fileURL
      fileLink.setAttribute('download', fileName)
      document.body.appendChild(fileLink)
      fileLink.click()
      return response
    })
  },
  getGetFile(resource, params, fileName) {
    return axios({
      url: `${apiBaseUrl}api/${resource}`,
      method: 'GET',
      data: params,
      responseType: 'blob',
    }).then(response => {
      const fileURL = window.URL.createObjectURL(new Blob([response.data]))
      const fileLink = document.createElement('a')
      fileLink.href = fileURL
      fileLink.setAttribute('download', fileName)
      document.body.appendChild(fileLink)
      fileLink.click()
      return response
    })
  },
}

export default ApiService
