import Vue from 'vue'
import Vuex from 'vuex'

import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

// Modules
import security from './modules/security'
import auth from './modules/auth'
import profile from './modules/profile'
import descuentoProductos from './modules/descuentoProductos'
import reportesInternos from './modules/reportesInternos'
import reportesMutualIntro from './modules/reportesMutualIntro'
import reportesMutualPractico from './modules/reportesMutualPractico'
import reportesMutualRol from './modules/reportesMutualRol'
import usuarios from './modules/usuarios/usuarios'
import reportesClientes from './modules/reportesClientes'
import reportesCursosPresenciales from './modules/reportesCursosPresenciales'
import reportesCursos19 from './modules/reportesCursos19'
import filtrosReportes from './modules/filtrosReportes'
import certificadoAlumno from './modules/certificadoAlumnos'
import codigoValidez from './modules/codigoValidez'
import listadoRectificaciones from './modules/rectificaciones'
import retencionSii from './modules/retencionSii'
import comisiones from './modules/comisiones'
import reporteSeguimientos from './modules/reporteSeguimientos'
import teletutoria from './modules/teletutoria'
import seguimientos from './modules/seguimientos'
import empresa from './modules/empresa'
import codigosTablet from './modules/codigosTablet'
import gestionarCertificados from './modules/gestionarCertificados'
import reporteBonoContact from "./modules/reporteBonoContact";
import imagenes from "./modules/imagenes";
import infoFicha from "./modules/infoFicha";
import gestionFiltros from "./modules/gestionFiltros";

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    security,
    verticalMenu,
    auth,
    descuentoProductos,
    profile,
    reportesInternos,
    usuarios,
    reportesClientes,
    reportesMutualIntro,
    reportesMutualPractico,
    reportesMutualRol,
    reportesCursosPresenciales,
    reportesCursos19,
    filtrosReportes,
    certificadoAlumno,
    codigoValidez,
    listadoRectificaciones,
    retencionSii,
    comisiones,
    empresa,
    reporteSeguimientos,
    teletutoria,
    seguimientos,
    codigosTablet,
    gestionarCertificados,
    reporteBonoContact,
    imagenes,
    infoFicha,
    gestionFiltros
  },
  strict: process.env.DEV,
})
