const routes = [
  {
    path: '/',
    redirect: { name: 'empresa' },
  },
  {
    path: '/empresa',
    name: 'empresa',
    requiresAuth: false,
    component: () => import('@/views/empresa/Empresa'),
    meta: {
      pageTitle: 'Empresa',
      requiresAuth: true,
      breadcrumb: [
        {
          text: 'Listar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/empresa/editar/:id',
    name: 'editarEmpresa',
    requiresAuth: false,
    component: () => import('@/views/empresa/EditarEmpresa.vue'),
    meta: {
      pageTitle: 'Empresa',
      requiresAuth: true,
      breadcrumb: [
        {
          text: 'Editar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/empresa/agregar',
    name: 'agregarEmpresa',
    requiresAuth: false,
    component: () => import('@/views/empresa/AgregarEmpresa.vue'),
    meta: {
      pageTitle: 'Empresa',
      requiresAuth: true,
      breadcrumb: [
        {
          text: 'Agregar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/empresa/detalle/:id',
    name: 'detalleEmpresa',
    requiresAuth: false,
    component: () => import('@/views/empresa/DetalleEmpresa.vue'),
    meta: {
      pageTitle: 'Empresa',
      requiresAuth: true,
      breadcrumb: [
        {
          text: 'Detalle',
          active: true,
        },
      ],
    },
  },
]

export default routes
