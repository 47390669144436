import ApiService from '@/webServices/api.service'

export default {
  namespaced: true,
  state: {
    retenciones: [],
    rowsTotal: 0,
    from: 0,
    to: 0,
    retencionEditar:[],
    currentPage: 1,
    loading: false,
    notificacion: {
      mensaje:'',
      variant:''
    },
  },
  getters: {

  },
  mutations: {
    setRetencionesListado(state, value) {
      state.retenciones = value
    },
    setTotal(state, value) {
      state.rowsTotal = value
    },
    setTo(state, value) {
      state.to = value
    },
    setForm(state, value) {
      state.from = value
    },
    setCurrentPage(state, value) {
      state.currentPage = value
    },
    setLoading(state, value) {
      state.loading = value
    },
    getRetencionToEdit(state, value) {
      state.retencionEditar = value
    },
    setNotificacionMensaje(state, value) {
      state.notificacion.mensaje = value
    },
    setNotificacionVariant(state, value) {
      state.notificacion.variant = value
    },

  },
  actions: {
    async getRetencionesList(context, data) {
      context.commit('setLoading', true)
      return ApiService
        .post('retenciones/listado', data)
        .then(response => {
          context.commit('setRetencionesListado', response.data.data)
          context.commit('setTotal', response.data.total)
          context.commit('setForm', response.data.from)
          context.commit('setTo', response.data.to)
          context.commit('setCurrentPage', response.data.current_page)
          context.commit('setLoading', false)
          context.commit('setNotificacionMensaje', '')
        })
        .catch(error => {
          console.log(error)
          context.commit('setLoading', false)
        })
    },
    async insertarRetencion(context, data) {
      context.commit('setLoading', true)
      return ApiService
        .post(`retenciones/insertar?periodo=${data.periodo
        }&porcentaje_retencion=${data.porcentaje_retencion}`)
        .then(response => {
          context.commit('setLoading', false)
          context.commit('setNotificacionMensaje', 'La Rectificación se ha insertado correctamente')
          context.commit('setNotificacionVariant', 'success')
        })
        .catch(error => {
          context.commit('setLoading', false)
          context.commit('setNotificacionMensaje', 'Hubo un error, no se ha podido crear una nueva Rectificación')
          context.commit('setNotificacionVariant', 'danger')
          console.log('error crear ', error)
        })
    },
    async editarRetencion(context, data) {
      context.commit('setLoading', true)
      return ApiService
        .post('retenciones/editar', data)
        .then(response => {
          context.commit('setLoading', false)
          context.commit('setNotificacionMensaje', 'La Rectificación se ha editado correctamente')
          context.commit('setNotificacionVariant', 'success')
        })
        .catch(error => {
          context.commit('setLoading', false)
          context.commit('setNotificacionMensaje', 'Hubo un error, no se ha podido editar la Rectificación')
          context.commit('setNotificacionVariant', 'danger')
          console.log('error crear ', error)
        })
    },
    async activarDesactivarRetencion(context, data) {
      context.commit('setLoading', true)
      return ApiService
        .post('retenciones/desactivar-activar', data)
        .then(response => {
          context.commit('setLoading', false)
          context.commit('setNotificacionMensaje', 'La Vigencia de la Rectificación se ha editado correctamente')
          context.commit('setNotificacionVariant', 'success')
        })
        .catch(error => {
          context.commit('setLoading', false)
          context.commit('setNotificacionMensaje', 'Hubo un error, no se ha podido editar la vigencia de la Rectificación')
          context.commit('setNotificacionVariant', 'danger')
          console.log('error Activar/Desactivar ', error)
        })
    },
  },
}
