const routes = [
  {
    path: '/',
    redirect: { name: 'certificado_alumno' },
  },
  {
    path: '/certificado-alumno',
    name: 'certificado_alumno',
    requiresAuth: false,
    component: () => import('@/views/certificadoAlumno/CertificadoAlumno'),
    meta: {
      pageTitle: 'Certificado de Alumno',
      requiresAuth: true,
      breadcrumb: [
        {
          text: 'Listado',
          active: true,
        },
      ],
    },
  },
  {
    path: '/generar-certificado',
    name: 'generar_certificado',
    requiresAuth: false,
    component: () => import('@/views/certificadoAlumno/ExportarCertificado'),
    meta: {
      pageTitle: 'Listado',
      requiresAuth: true,
      breadcrumb: [
        {
          text: 'Generar Certificado de Alumno',
          active: true,
        },
      ],
    },
  },
]

export default routes
