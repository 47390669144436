
import ApiService from "@/webServices/api.service";

export default {
    namespaced: true,
    state: {
        list: [],
        rowsTotal: 0,
        from: 0,
        to: 0,
        rowsTotalSelect: 0,
        exportData: [],
        loading:false,
        codeMissing:false,
        mensaje:'',
    },
    getters: {},
    mutations: {
        setList(state, value) {
            state.list = value
        },
        setTotal(state, value) {
            state.rowsTotal = value
        },
        setTo(state, value) {
            state.to = value
        },
        setForm(state, value) {
            state.from = value
        },
        setCurrentPage(state, value) {
            state.currentPage = value
        },
        setLoading(state, value) {
            state.loading = value
        },
        setCodeMissing(state, value) {
            state.codeMissing = value
        },
        setMensaje(state, value) {
            state.mensaje = value
        },
    },
    actions:{
        async getList(context, data) {
            context.commit('setLoading', true)
            const url = "edu-tablet_client/code/list?ficha_numero=" + data.num_ficha+ '&page=' + data.page + '&rowsPerPage=' + data.perPage;
            return ApiService
                .post(url, data)
                .then(response => {
                    if(response.data.list ){
                        context.commit('setList', response.data.list.data)
                        context.commit('setTotal', response.data.list.total)
                        context.commit('setForm', response.data.list.from)
                        context.commit('setTo', response.data.list.to)
                        context.commit('setCurrentPage', response.data.list.current_page)
                        context.commit('setLoading', false)
                        context.commit('setCodeMissing', response.data.code_missing)
                        context.commit('setMensaje','')
                    }else {
                        context.commit('setMensaje',response.data.message)
                        context.commit('setList', [])
                        context.commit('setTotal', 0)
                        context.commit('setForm', 0)
                        context.commit('setTo', 0)
                        context.commit('setCurrentPage', 0)
                        context.commit('setCodeMissing', '')
                        context.commit('setLoading', false)
                    }

                })
                .catch(error => {
                    console.log(error)
                    context.commit('setList', [])
                    context.commit('setTotal', 0)
                    context.commit('setForm', 0)
                    context.commit('setTo', 0)
                    context.commit('setCurrentPage', 0)
                    context.commit('setLoading', false)
                })
        },
        async exportData(context, data) {
            context.commit('setLoading', true)
            const url = "edu-tablet_client/code/excel-export?ficha_numero=" + data;
            return ApiService
                .postGetFile(url, data,"Codigos_tablet.xlsx")
                .then(response => {
                    context.commit('setLoading', false)
                    console.log(response)
                })
                .catch(error => {
                    context.commit('setLoading', false)
                    console.log(error)
                })
        },
        async generateCode(context, data) {
            context.commit('setLoading', true)
            const url = "edu-tablet_client/code/generate?ficha_numero=" + data;
            return ApiService
                .post(url, data)
                .then(response => {
                    context.commit('setList', response.data.list.data)
                    context.commit('setTotal', response.data.list.total)
                    context.commit('setForm', response.data.list.from)
                    context.commit('setTo', response.data.list.to)
                    context.commit('setCurrentPage', response.data.list.current_page)
                    context.commit('setLoading', false)
                    context.commit('setCodeMissing', response.data.code_missing)
                    context.commit('setMensaje','')
                })
                .catch(error => {
                    context.commit('setLoading', false)
                    console.log(error)
                })
        },
    },
}
