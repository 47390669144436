
import ApiService from "@/webServices/api.service";

export default {
    namespaced: true,
    state: {
        years: [],
        rowsTotalSelect: 0,
        toSelect: 0,
        lastPage: 0,
        valueFilter: [],
        currentPage: 1,
        loading:false,
        loadingYears:false,
        mensaje: '',

    },
    getters: {},
    mutations: {
        setYear(state, value) {
            state.years = value
        },
        setTotalSelect(state, value) {
            state.rowsTotalSelect = value
        },
        setToSelect(state, value) {
            state.toSelect = value
        },
        setLastPage(state, value) {
            state.lastPage = value
        },
        setLoading(state, value) {
            state.loading = value
        },
        setLoadingYears(state, value) {
            state.loadingYears = value
        },
        setMensaje(state, value) {
            state.mensaje = value
        },
    },
    actions: {
        async getYears(context, data) {
            context.commit('setLoadingYears', true)
            const url = 'filters/listado-years?type_curso='+ data.type_curso;
            return ApiService
                .get(url, data)
                .then(response => {
                    context.commit('setYear', response.data)
                    context.commit('setLoadingYears', false)
                })
                .catch(error => {
                    console.log(error)
                    context.commit('setLoadingYears', false)
                })
        },
        async sendFilters(context, data) {
            context.commit('setLoading', true)
            const url = 'filters/agregar-filtro';
            return ApiService
                .post(url, data)
                .then(response => {
                    context.commit('setMensaje', response.data.status)
                    context.commit('setLoading', false)
                })
                .catch(error => {
                    console.log(error)
                    context.commit('setLoading', false)
                })
        },
    },
}
