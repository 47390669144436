import Vue from 'vue'

Vue.prototype.$handleErrorValidation = function (message = '', title = "Hey, something's wrong...") {
  message = message || 'Please, correct any errors in the form before continuing.'

  this.$notify(title, message, 'XCircleIcon', 'warning')
}

Vue.prototype.$handleSuccessValidation = function (message = '', title = 'Success!') {
  message = message || '¡Validación exitosa!'

  this.$notify(title, message, 'XCircleIcon', 'success')
}
