const routes = [
  {
    path: '/',
    redirect: { name: 'tutores' },
  },
  {
    path: '/tutores',
    name: 'tutores',
    requiresAuth: false,
    component: () => import('@/views/teletutoria/Teletutoria'),
    meta: {
      pageTitle: 'Tutores',
      requiresAuth: true,
      breadcrumb: [
        {
          text: 'Tutores',
          active: true,
        },
      ],
    },
  },
]

export default routes
