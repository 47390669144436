const routes = [
  {
    path: '/',
    redirect: { name: 'retencion_sii' },
  },
  {
    path: '/retencion-sii',
    name: 'retencion_sii',
    requiresAuth: false,
    component: () => import('@/views/retencionSii/RetencionSii'),
    meta: {
      pageTitle: 'Retencion Sii',
      requiresAuth: true,
      breadcrumb: [
        {
          text: 'Retencion Sii',
          active: true,
        },
      ],
    },
  },
]

export default routes
