const routes = [
    {
        path: '/',
        redirect: { name: 'reportes_clientes' },
    },
    {
        path: '/reportes-clientes',
        name: 'reportes_clientes',
        requiresAuth: false,
        component: () => import('@/views/reportesClientes/ReportesClientes'),
        meta: {
            pageTitle: 'Reportes clientes',
            requiresAuth: true,
            breadcrumb: [
                {
                    text: 'Reportes clientes',
                    active: true,
                },
            ],
        },
    },
]

export default routes
