const routes = [
    {
        path: '/',
        redirect: { name: 'codigo_tablet' },
    },
    {
        path: '/codigo-tablet',
        name: 'codigo_tablet',
        requiresAuth: false,
        component: () => import('@/views/codigosTablet/CodigosTablet'),
        meta: {
            pageTitle: 'Códigos de tablet',
            requiresAuth: true,
            breadcrumb: [
                {
                    text: 'Gestionar códigos',
                    active: true,
                },
            ],
        },
    },
]

export default routes
