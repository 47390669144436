
import ApiService from "@/webServices/api.service";

export default {
    namespaced: true,
    state: {
        list: [],
        holding: [],
        rowsTotal: 0,
        from: 0,
        to: 0,
        rowsTotalSelect: 0,
        exportData: [],
        loading: false,
    },
    getters: {},
    mutations: {
        setList(state, value) {
            state.list = value
        },
        setTotal(state, value) {
            state.rowsTotal = value
        },
        setTo(state, value) {
            state.to = value
        },
        setForm(state, value) {
            state.from = value
        },

        setCurrentPage(state, value) {
            state.currentPage = value
        },
        setLoading(state, value) {
            state.loading = value
        },
    },
    actions: {
        async getList(context, data) {
            context.commit('setLoading', true)
            const url = "reporte-cursos19/data?sort_by=" + data.sort_by + '&page=' + data.page + '&item_per_page=' + data.perPage;
            return ApiService
                .post(url, data)
                .then(response => {
                    context.commit('setList', response.data.data)
                    context.commit('setTotal', response.data.total)
                    context.commit('setForm', response.data.from)
                    context.commit('setTo', response.data.to)
                    context.commit('setCurrentPage', response.data.current_page)
                    context.commit('setLoading', false)
                })
                .catch(error => {
                    console.log(error)
                    context.commit('setList', [])
                    context.commit('setTotal', 0)
                    context.commit('setForm', 0)
                    context.commit('setTo', 0)
                    context.commit('setCurrentPage', 0)
                    context.commit('setLoading', false)
                })
        },
        async excelExportData(context, data) {
            context.commit('setLoading', true)
            const url = 'reporte-cursos19/excel-export';
            if(data.exportInBack == true){
                return ApiService
                    .post(url, data)
                    .then(response => {
                        context.commit('setLoading', false)
                        console.log(response)
                    })
                    .catch(error => {
                        context.commit('setLoading', false)
                        console.log(error)
                    })
            }else {
                return ApiService
                    .postGetFile(url, data, "Reporte_datos cursos 1-9.xlsx")
                    .then(response => {
                        context.commit('setLoading', false)
                        console.log(response)
                    })
                    .catch(error => {
                        context.commit('setLoading', false)
                        console.log(error)
                    })
            }
        },
    },
}
