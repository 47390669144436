import ApiService from '@/webServices/api.service'

export default {
  namespaced: true,
  state: {
    usuarios: [],
  },
  getters: {

  },
  mutations: {
    setUsuarios(state, value) {
      state.usuarios = value
    },
  },
  actions: {
    async getUsuarios(context, data) {
      return ApiService
        .get('comisiones/listado', data)
        .then(response => {
          context.commit('setUsuarios', response)
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
